import React, { useCallback, useRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useAINotebook } from '../context/AINotebookContext';
import StatusMessage from './StatusMessage';
import { debounce } from 'lodash';
import { FiSun, FiMoon, FiType } from 'react-icons/fi';
import AIPromptInput from './AIPromptInput';
import NotesList from './NotesList';

const EditorContainer = styled.div`
  display: flex;
  height: 100vh;
  background: var(--editor-bg);
  transition: all 0.3s ease;
`;

const EditorContent = styled.div`
  flex: 1;
  height: 100vh;
  padding: 10rem 4rem 2rem;
  overflow-y: auto;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 6rem 2rem 2rem;
  }
`;

const ContentArea = styled.div`
  position: relative;
  min-height: calc(100vh - 7rem);
  max-width: 860px;
  margin: 0 auto;
  font-size: ${props => props.fontSize}rem;
  line-height: 1.8;
  color: var(--primary-color);
`;

const Input = styled.textarea`
  width: 100%;
  min-height: calc(100vh - 7rem);
  border: none;
  background: transparent;
  color: var(--primary-color);
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  resize: none;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--placeholder-color);
    opacity: 0.5;
  }
`;

const SuggestionLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  pointer-events: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: transparent;

  .ghost {
    color: var(--ghost-text);
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
  }
`;

const Controls = styled.div`
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  display: flex;
  gap: 0.5rem;
  z-index: 10;
  background: var(--control-bg);
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const ControlButton = styled.button`
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 6px;
  background: var(--button-bg);
  color: var(--button-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: var(--button-hover-bg);
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const FontSizeMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: var(--control-bg);
  border-radius: 6px;
  padding: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  gap: 0.25rem;
  backdrop-filter: blur(8px);
  animation: slideIn 0.2s ease;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const FontSizeOption = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background: ${props => props.isActive ? 'var(--button-hover-bg)' : 'transparent'};
  color: var(--button-color);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: var(--button-hover-bg);
  }
`;

function Editor() {
  const {
    content,
    suggestion,
    updateContent,
    getAISuggestion,
    acceptSuggestion,
    clearSuggestion,
    theme,
    toggleTheme,
    fontSize,
    setFontSize,
    setStatus,
    API_KEY,
    BASE_URL
  } = useAINotebook();
  
  const [fontSizeMenuOpen, setFontSizeMenuOpen] = React.useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [isPromptOpen, setIsPromptOpen] = React.useState(false);
  const [selectedText, setSelectedText] = React.useState('');
  const [currentNote, setCurrentNote] = useState({
    id: 1,
    name: '正在编辑的笔记',
    content: ''
  });

  const fontSizes = [1, 1.1, 1.2, 1.3, 1.4, 1.5];

  const debouncedGetAISuggestion = useCallback(
    debounce((text, position) => {
      getAISuggestion(text, position);
    }, 1000),
    [getAISuggestion]
  );

  const handleInput = useCallback((e) => {
    const newContent = e.target.value;
    const cursorPosition = e.target.selectionStart;
    
    if (suggestion && (newContent.length < content.length || 
        newContent[cursorPosition - 1] === ' ')) {
      clearSuggestion();
    }
    
    updateContent(newContent, cursorPosition);
    debouncedGetAISuggestion(newContent, cursorPosition);
  }, [updateContent, debouncedGetAISuggestion, suggestion, clearSuggestion, content]);

  const handlePromptSubmit = useCallback(async (prompt) => {
    const cursorPosition = inputRef.current.selectionStart;
    const selectionEnd = inputRef.current.selectionEnd;
    const contextBefore = content.substring(Math.max(0, cursorPosition - 100), cursorPosition);
    const contextAfter = content.substring(selectionEnd, selectionEnd + 100);

    try {
      setStatus('AI思考中...');
      
      const response = await fetch(`${BASE_URL}chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`,
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          model: 'gpt-4o-mini',
          messages: [
            {
              role: 'system',
              content: selectedText
                ? '你是一个写作助手，请根据用户的提示生成新的内容替换选中的文本，保持上下文的连贯性。'
                : '你是一个写作助手，请根据用户的提示生成新的内容，要符合上下文并保持连贯性。'
            },
            {
              role: 'user',
              content: `上文：${contextBefore}\n${selectedText ? `要替换的文本：${selectedText}\n` : ''}下文：${contextAfter}\n\n用户提示：${prompt}`
            }
          ],
          max_tokens: 150,
          temperature: 0.7
        })
      });

      const data = await response.json();
      
      if (data.choices && data.choices[0] && data.choices[0].message) {
        const newText = data.choices[0].message.content.trim();
        
        if (selectedText) {
          // 替换选中文本
          const newContent = content.substring(0, cursorPosition) +
                           newText +
                           content.substring(selectionEnd);
          updateContent(newContent, cursorPosition + newText.length);
        } else {
          // 在光标位置插入新内容
          const newContent = content.substring(0, cursorPosition) +
                           newText +
                           content.substring(cursorPosition);
          updateContent(newContent, cursorPosition + newText.length);
        }
        
        setStatus('内容已更新');
      }
    } catch (error) {
      console.error('AI 处理失败:', error);
      setStatus('AI 处理失败，请重试');
    }
  }, [content, selectedText, updateContent, setStatus, BASE_URL, API_KEY]);

  const handleKeyDown = useCallback((e) => {
    if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'm') {
      console.log('Ctrl+M detected');
      e.preventDefault();
      e.stopPropagation();
      const selection = window.getSelection();
      const selectedText = selection.toString();
      setSelectedText(selectedText);
      setIsPromptOpen(true);
      return;
    }

    if (e.key === 'Tab' && suggestion) {
      e.preventDefault();
      acceptSuggestion();
    } else if (suggestion && (e.key === ' ' || e.key === 'Escape')) {
      clearSuggestion();
    }
  }, [suggestion, acceptSuggestion, clearSuggestion]);

  const handleSelect = useCallback(() => {
    const selection = window.getSelection();
    setSelectedText(selection.toString());
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (fontSizeMenuOpen && !event.target.closest('.font-size-control')) {
        setFontSizeMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [fontSizeMenuOpen]);

  useEffect(() => {
    const handleGlobalKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'm') {
        console.log('Global Ctrl+M detected');
        e.preventDefault();
        e.stopPropagation();
        const selection = window.getSelection();
        setSelectedText(selection.toString());
        setIsPromptOpen(true);
      }
    };

    document.addEventListener('keydown', handleGlobalKeyDown, { capture: true });
    return () => document.removeEventListener('keydown', handleGlobalKeyDown, { capture: true });
  }, []);

  const handleNoteSelect = (note) => {
    setCurrentNote(note);
  };

  const handleNoteRename = (id, newName) => {
    if (currentNote.id === id) {
      setCurrentNote(prev => ({ ...prev, name: newName }));
    }
  };

  return (
    <EditorContainer>
      <NotesList
        currentNote={currentNote}
        onNoteSelect={handleNoteSelect}
        onNoteRename={handleNoteRename}
      />
      <EditorContent>
        <Controls>
          <ControlButton onClick={toggleTheme} title={theme === 'light' ? '切换到暗色模式' : '切换到亮色模式'}>
            {theme === 'light' ? <FiMoon /> : <FiSun />}
          </ControlButton>
          <div style={{ position: 'relative' }} className="font-size-control">
            <ControlButton 
              onClick={() => setFontSizeMenuOpen(!fontSizeMenuOpen)}
              title="调整字号"
            >
              <FiType />
            </ControlButton>
            <FontSizeMenu isOpen={fontSizeMenuOpen}>
              {fontSizes.map(size => (
                <FontSizeOption
                  key={size}
                  isActive={fontSize === size}
                  onClick={() => {
                    setFontSize(size);
                    setFontSizeMenuOpen(false);
                  }}
                >
                  {size * 100}%
                </FontSizeOption>
              ))}
            </FontSizeMenu>
          </div>
        </Controls>
        <ContentArea fontSize={fontSize}>
          <Input
            ref={inputRef}
            value={content}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
            onSelect={handleSelect}
            placeholder="开始写作... (按 Ctrl+M 打开 AI 助手)"
            spellCheck={false}
          />
          <SuggestionLayer>
            <span style={{ color: 'transparent' }}>{content}</span>
            {suggestion && <span className="ghost">{suggestion}</span>}
          </SuggestionLayer>
        </ContentArea>
      </EditorContent>
      <StatusMessage />
      <AIPromptInput
        isOpen={isPromptOpen}
        onClose={() => {
          setIsPromptOpen(false);
          setSelectedText('');
        }}
        onSubmit={handlePromptSubmit}
        selectedText={selectedText}
        contextText={content.substring(
          Math.max(0, inputRef.current?.selectionStart - 50) || 0,
          (inputRef.current?.selectionStart + 50) || 50
        )}
      />
    </EditorContainer>
  );
}

export default Editor; 