import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { FiEdit2 } from 'react-icons/fi';

const NotesListContainer = styled.div`
  width: 250px;
  height: 100vh;
  background: var(--bg-color);
  padding: 10rem 1rem 2rem;
  overflow-y: auto;
  transition: all 0.3s ease;
`;

const NoteItem = styled.div`
  padding: 0.75rem 1rem;
  margin-bottom: 0.75rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.active ? 'var(--button-bg)' : 'transparent'};
  color: var(--primary-color);
  transition: all 0.2s ease;

  &:hover {
    background: var(--button-hover-bg);
  }

  svg {
    opacity: ${props => props.active ? 0.5 : 0};
  }

  &:hover svg {
    opacity: 0.5;
  }
`;

const NoteName = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.9rem;
`;

const NoteInput = styled.input`
  width: 100%;
  padding: 0.25rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--bg-color);
  color: var(--primary-color);
  font-size: 0.9rem;

  &:focus {
    outline: none;
    border-color: var(--primary-color);
  }
`;

function NotesList({ currentNote, onNoteSelect, onNoteRename }) {
  const [notes, setNotes] = useState([
    { id: 1, name: '正在编辑的笔记', content: '' }
  ]);
  const [editingId, setEditingId] = useState(null);
  const [editingName, setEditingName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (editingId && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingId]);

  const handleDoubleClick = (note) => {
    setEditingId(note.id);
    setEditingName(note.name);
  };

  const handleNameChange = (e) => {
    setEditingName(e.target.value);
  };

  const handleNameSubmit = () => {
    if (editingName.trim()) {
      onNoteRename(editingId, editingName.trim());
      setNotes(notes.map(note => 
        note.id === editingId 
          ? { ...note, name: editingName.trim() }
          : note
      ));
    }
    setEditingId(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleNameSubmit();
    } else if (e.key === 'Escape') {
      setEditingId(null);
    }
  };

  return (
    <NotesListContainer>
      {notes.map(note => (
        <NoteItem 
          key={note.id}
          active={currentNote?.id === note.id}
          onClick={() => onNoteSelect(note)}
          onDoubleClick={() => handleDoubleClick(note)}
        >
          {editingId === note.id ? (
            <NoteInput
              ref={inputRef}
              value={editingName}
              onChange={handleNameChange}
              onBlur={handleNameSubmit}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <>
              <NoteName>{note.name}</NoteName>
              <FiEdit2 size={14} />
            </>
          )}
        </NoteItem>
      ))}
    </NotesListContainer>
  );
}

export default NotesList; 