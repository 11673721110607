import { createClient } from '@supabase/supabase-js'
import bcrypt from 'bcryptjs'

const supabase = createClient(
  'https://dcylnbshlnjksqziycju.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRjeWxuYnNobG5qa3Nxeml5Y2p1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzE1MDMzNTEsImV4cCI6MjA0NzA3OTM1MX0.5I50TCcXDBj75f6kFqYmB9IQdR14i1j8_Or1K8vccL4'
)

class AuthService {
  // 发送验证码
  async sendVerificationCode(email) {
    try {
      const response = await fetch(`https://ygth6uqm5m.hzh.sealos.run/send-code?email=${encodeURIComponent(email)}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      const data = await response.json();
      console.log('发送验证码数据:', data); // 调试日志

      // 解析嵌套的 body 字符串
      const bodyData = JSON.parse(data.body);
      
      if (bodyData.ok) {
        return {
          success: true,
          message: bodyData.msg
        };
      } else {
        throw new Error(bodyData.msg || '发送验证码失败');
      }
    } catch (error) {
      console.error('发送验证码错误:', error);
      throw new Error(error.message || '发送验证码失败');
    }
  }

  // 验证邮箱验证码
  async verifyCode(email, code) {
    try {
      console.log('验证码请求参数:', { email, code }); // 调试日志
      
      const params = new URLSearchParams({
        email: email,
        code: code.toString().trim()
      });

      const response = await fetch(`https://ygth6uqm5m.hzh.sealos.run/verify_code?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      const data = await response.json();
      console.log('验证码响应数据:', data); // 调试日志

      // 解析嵌套的 body 字符串
      const bodyData = JSON.parse(data.body);

      if (!bodyData.ok) {
        throw new Error(bodyData.msg || '验证码验证失败');
      }

      return {
        success: true,
        message: bodyData.msg
      };
    } catch (error) {
      console.error('验证码验证详细错误:', error);
      throw new Error(error.message || '验证码验证失败');
    }
  }

  // 用户注册
  async register(username, email, password, verificationCode) {
    try {
      console.log('开始注册流程'); // 调试日志

      // 1. 先验证邮箱验证码
      console.log('验证码验证开始'); // 调试日志
      const verifyResult = await this.verifyCode(email, verificationCode);
      console.log('验证码验证结果:', verifyResult); // 调试日志

      if (!verifyResult.success) {
        throw new Error(verifyResult.message || '验证码验证失败');
      }

      // 2. 检查用户名和邮箱是否已存在
      const { data: existingUser, error: searchError } = await supabase
        .from('users')
        .select()
        .or(`email.eq.${email},username.eq.${username}`)
        .single();

      if (searchError && searchError.code !== 'PGRST116') {
        // PGRST116 是未找到记录的错误码
        throw new Error('检查用户信息失败');
      }

      if (existingUser) {
        throw new Error('用户名或邮箱已存在');
      }

      // 3. 密码加密
      const passwordHash = await bcrypt.hash(password, 10);

      // 4. 创建用户
      const { data, error } = await supabase
        .from('users')
        .insert([
          {
            username,
            email,
            password_hash: passwordHash,
          }
        ])
        .select()
        .single();

      if (error) {
        console.error('创建用户错误:', error); // 详细错误日志
        throw new Error('创建用户失败');
      }

      console.log('注册成功'); // 调试日志
      return data;
    } catch (error) {
      console.error('注册详细错误:', error); // 详细错误日志
      throw new Error(error.message || '注册失败，请稍后重试');
    }
  }

  // 用户登录
  async login(email, password) {
    try {
      // 1. 查找用户
      const { data: user, error } = await supabase
        .from('users')
        .select()
        .eq('email', email)
        .single();

      if (error || !user) {
        throw new Error('用户不存在');
      }

      // 2. 验证密码
      const validPassword = await bcrypt.compare(password, user.password_hash);
      if (!validPassword) {
        throw new Error('密码错误');
      }

      // 3. 更新最后登录时间
      await supabase
        .from('users')
        .update({ last_login: new Date().toISOString() })
        .eq('id', user.id);

      // 4. 记录登录历史
      await supabase
        .from('login_history')
        .insert([
          {
            user_id: user.id,
            ip_address: window.clientIP || 'unknown',
            user_agent: navigator.userAgent
          }
        ]);

      // 5. 返回用户信息(不包含密码)
      const { password_hash, ...userWithoutPassword } = user;
      return userWithoutPassword;
    } catch (error) {
      console.error('登录错误:', error);
      throw new Error(error.message || '登录失败');
    }
  }
}

export default new AuthService(); 