import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { marked } from 'marked';

const AINotebookContext = createContext();

const API_KEY = 'sk-ARexBsdLWlirjQLEE7oxDPHrSmb3OB8brwoI7jpJSEk5k40K';
const BASE_URL = 'https://www.DMXapi.com/v1/';

export function AINotebookProvider({ children }) {
  const [content, setContent] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [status, setStatus] = useState('');
  const [preview, setPreview] = useState('');
  const [theme, setTheme] = useState('light');
  const [fontSize, setFontSize] = useState(1.1);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = useCallback((userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('user');
  }, []);

  const updateContent = useCallback((newContent, newCursorPosition, shouldClearSuggestion = true) => {
    setContent(newContent);
    setCursorPosition(newCursorPosition);
    setPreview(marked(newContent));
    if (shouldClearSuggestion) {
      setSuggestion('');
      setStatus('');
    }
  }, []);

  const getAISuggestion = useCallback(async (text, position) => {
    // 获取光标左侧的文本
    const leftText = text.substring(0, position);
    
    // 如果光标左侧文本为空或长度小于10或以空格结尾，不触发建议
    if (!leftText || leftText.length < 10 || leftText.endsWith(' ')) {
      setSuggestion('');
      return;
    }

    try {
      setStatus('AI思考中...');
      
      const response = await fetch(`${BASE_URL}chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`,
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          model: 'gpt-4o-mini',
          messages: [
            {
              role: 'system',
              content: '你是一个写作助手，请根据用户输入的文本提供简短、自然的续写建议。建议要符合上下文，并保持连贯性。'
            },
            {
              role: 'user',
              content: `请为以下文本提供一个自然的续写（直接给出续写内容，不要重复已有内容）：${leftText}`
            }
          ],
          max_tokens: 5000,
          temperature: 0.7,
          presence_penalty: 0.6,
          frequency_penalty: 0.5
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('API 错误详情:', errorData);
        
        if (response.status === 429) {
          throw new Error('请求过于频繁，请稍后再试');
        } else if (response.status === 401) {
          throw new Error('API 密钥无效');
        } else {
          throw new Error(`API 请求失败: ${response.status}`);
        }
      }

      const data = await response.json();

      if (data.choices && data.choices[0] && data.choices[0].message) {
        const suggestion = data.choices[0].message.content.trim();
        setSuggestion(suggestion);
        setStatus('建议已就绪');
      } else {
        throw new Error('API 返回格式不正确');
      }
    } catch (error) {
      console.error('AI 补全请求失败:', error);
      setStatus(`${error.message}`);
      setSuggestion('');
      
      if (error.message.includes('频繁')) {
        setTimeout(() => {
          setStatus('');
        }, 5000);
      }
    }
  }, []);

  const acceptSuggestion = useCallback(() => {
    if (!suggestion) return;
    // 在光标位置插入建议内容
    const newContent = content.substring(0, cursorPosition) + 
                      suggestion + 
                      content.substring(cursorPosition);
    updateContent(newContent, cursorPosition + suggestion.length, true);
    setSuggestion('');
    setStatus('已接受建议');
  }, [content, suggestion, cursorPosition, updateContent]);

  const clearSuggestion = useCallback(() => {
    setSuggestion('');
    setStatus('');
  }, []);

  const toggleTheme = useCallback(() => {
    setTheme(prev => {
      const newTheme = prev === 'light' ? 'dark' : 'light';
      document.documentElement.setAttribute('data-theme', newTheme);
      return newTheme;
    });
  }, []);

  const value = {
    content,
    suggestion,
    status,
    preview,
    updateContent,
    getAISuggestion,
    acceptSuggestion,
    clearSuggestion,
    setStatus,
    theme,
    toggleTheme,
    fontSize,
    setFontSize,
    cursorPosition,
    API_KEY,
    BASE_URL,
    user,
    login,
    logout
  };

  return (
    <AINotebookContext.Provider value={value}>
      {children}
    </AINotebookContext.Provider>
  );
}

export const useAINotebook = () => useContext(AINotebookContext); 
