import React from 'react';
import styled from '@emotion/styled';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Editor from './components/Editor';
import AuthPage from './pages/AuthPage';
import ControlBar from './components/ControlBar';
import { AINotebookProvider } from './context/AINotebookContext';

const AppContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: var(--bg-color);
  color: var(--primary-color);
`;

function App() {
  return (
    <Router>
      <AINotebookProvider>
        <AppContainer>
          <ControlBar />
          <Routes>
            <Route path="/" element={<Editor />} />
            <Route path="/auth" element={<AuthPage />} />
          </Routes>
        </AppContainer>
      </AINotebookProvider>
    </Router>
  );
}

export default App; 