import React from 'react';
import styled from '@emotion/styled';
import { useAINotebook } from '../context/AINotebookContext';

const StatusContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%) translateY(${props => props.visible ? 0 : '20px'});
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background: rgba(45, 55, 72, 0.9);
  color: white;
  font-size: 0.875rem;
  opacity: ${props => props.visible ? 1 : 0};
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
`;

function StatusMessage() {
  const { status } = useAINotebook();

  return (
    <StatusContainer visible={!!status}>
      {status}
    </StatusContainer>
  );
}

export default StatusMessage; 