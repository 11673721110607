import React, { useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { FiMessageSquare } from 'react-icons/fi';

const PromptContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 90%;
  max-width: 600px;
  animation: slideIn 0.2s ease;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translate(-50%, -45%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
`;

const PromptInput = styled.div`
  background: var(--control-bg);
  backdrop-filter: blur(12px);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid var(--border-color);

  &:hover {
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
`;

const Icon = styled(FiMessageSquare)`
  width: 20px;
  height: 20px;
  color: var(--button-color);
  opacity: 0.7;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  color: var(--primary-color);
  font-size: 1rem;
  padding: 0.5rem;
  font-family: inherit;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--placeholder-color);
    opacity: 0.7;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  z-index: 999;
  animation: fadeIn 0.2s ease;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const ContextPreview = styled.div`
  padding: 0.5rem 1rem;
  border-top: 1px solid var(--border-color);
  font-size: 0.875rem;
  color: var(--ghost-text);
  max-height: 100px;
  overflow-y: auto;
  background: var(--bg-color);
  opacity: 0.8;
`;

function AIPromptInput({ 
  isOpen, 
  onClose, 
  onSubmit, 
  selectedText,
  contextText 
}) {
  const inputRef = useRef(null);
  const [prompt, setPrompt] = React.useState('');

  const handleSubmit = useCallback(() => {
    if (prompt.trim()) {
      onSubmit(prompt);
      setPrompt('');
      onClose();
    }
  }, [prompt, onSubmit, onClose]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    } else if (e.key === 'Escape') {
      onClose();
    }
  }, [handleSubmit, onClose]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <Overlay onClick={onClose} />
      <PromptContainer>
        <PromptInput>
          <InputWrapper>
            <Icon />
            <Input
              ref={inputRef}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={selectedText 
                ? "请输入如何调整选中的文本..." 
                : "请输入要添加的内容提示..."}
            />
          </InputWrapper>
          {(selectedText || contextText) && (
            <ContextPreview>
              {selectedText ? `选中内容：${selectedText}` : `上下文：${contextText}`}
            </ContextPreview>
          )}
        </PromptInput>
      </PromptContainer>
    </>
  );
}

export default AIPromptInput; 