import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { FaUserCircle, FaMoon, FaSun, FaFont, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAINotebook } from '../context/AINotebookContext';

const ControlsContainer = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 8px;
  background: var(--control-bg);
  backdrop-filter: blur(4px);
`;

const ControlButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: var(--primary-color);
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  &:not(:last-child) {
    border-right: 1px solid var(--border-color);
    padding-right: 0.75rem;
    margin-right: 0.25rem;
  }
`;

const UserText = styled.span`
  font-size: 0.875rem;
`;

const FontSizeButton = styled.button`
  padding: 0.25rem 0.5rem;
  background: none;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    background: var(--button-hover-bg);
  }
`;

const UserMenu = styled.div`
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0.5rem;
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  min-width: 200px;
  backdrop-filter: blur(8px);
  z-index: 101;
`;

const MenuItem = styled.div`
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-color);

  &:hover {
    background-color: var(--button-hover-bg);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const UserInfo = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.5rem;
`;

const Email = styled.div`
  font-size: 0.75rem;
  color: var(--ghost-text);
  margin-top: 0.25rem;
`;

const MenuContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

function ControlBar() {
  const navigate = useNavigate();
  const { user, logout, theme, toggleTheme, fontSize, setFontSize } = useAINotebook();
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const adjustFontSize = (delta) => {
    setFontSize(prev => Math.max(0.8, Math.min(1.4, prev + delta)));
  };

  const handleUserClick = () => {
    if (!user) {
      navigate('/auth');
    } else {
      setShowMenu(!showMenu);
    }
  };

  const handleLogout = () => {
    logout();
    setShowMenu(false);
    navigate('/auth');
  };

  return (
    <MenuContainer ref={menuRef}>
      <ControlsContainer>
        <ControlButton onClick={handleUserClick}>
          <FaUserCircle size={16} />
          <UserText>
            {user ? user.username : '请登录'}
          </UserText>
        </ControlButton>

        <ControlButton onClick={toggleTheme}>
          {theme === 'light' ? <FaMoon size={16} /> : <FaSun size={16} />}
        </ControlButton>

        <ControlButton>
          <FaFont size={16} />
          <FontSizeButton onClick={() => adjustFontSize(-0.1)}>A-</FontSizeButton>
          <FontSizeButton onClick={() => adjustFontSize(0.1)}>A+</FontSizeButton>
        </ControlButton>
      </ControlsContainer>

      {showMenu && user && (
        <UserMenu>
          <UserInfo>
            <UserText>{user.username}</UserText>
            <Email>{user.email}</Email>
          </UserInfo>
          <MenuItem onClick={handleLogout}>
            <FaSignOutAlt />
            退出登录
          </MenuItem>
        </UserMenu>
      )}
    </MenuContainer>
  );
}

export default ControlBar; 