import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { useAINotebook } from '../context/AINotebookContext';

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background: var(--bg-color);
`;

const AuthCard = styled.div`
  background: var(--editor-bg);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--border-color);
`;

const Tab = styled.button`
  flex: 1;
  padding: 1rem;
  border: none;
  background: none;
  color: ${props => props.active ? 'var(--primary-color)' : 'var(--ghost-text)'};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  border-bottom: 2px solid ${props => props.active ? 'var(--primary-color)' : 'transparent'};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: var(--primary-color);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--bg-color);
  color: var(--primary-color);

  &:focus {
    outline: none;
    border-color: var(--primary-color);
  }
`;

const Button = styled.button`
  padding: 0.75rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

function AuthPage() {
  const navigate = useNavigate();
  const { login, user } = useAINotebook();
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    verificationCode: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSendCode = async () => {
    if (!formData.email) {
      setError('请输入邮箱地址');
      return;
    }
    
    try {
      setIsLoading(true);
      const result = await AuthService.sendVerificationCode(formData.email);
      if (result.success) {
        setError('验证码已发送到您的邮箱，请注意查收');
      } else {
        setError(result.message || '发送验证码失败');
      }
    } catch (err) {
      setError(err.message || '发送验证码失败');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (isLogin) {
        const userData = await AuthService.login(formData.email, formData.password);
        login(userData);
        navigate('/');
      } else {
        // 表单验证
        if (!formData.username || !formData.email || !formData.password || !formData.verificationCode) {
          throw new Error('请填写所有必填项');
        }
        
        if (formData.password.length < 6) {
          throw new Error('密码长度至少6位');
        }

        // 验证邮箱格式
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
          throw new Error('请输入有效的邮箱地址');
        }

        console.log('开始注册，验证码:', formData.verificationCode); // 调试日志

        await AuthService.register(
          formData.username,
          formData.email,
          formData.password,
          formData.verificationCode
        );
        
        setIsLogin(true);
        setError('注册成功，请登录');
        // 清空表单
        setFormData({
          username: '',
          email: '',
          password: '',
          verificationCode: ''
        });
      }
    } catch (err) {
      console.error('表单提交错误:', err); // 调试日志
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContainer>
      <AuthCard>
        <TabContainer>
          <Tab
            active={isLogin}
            onClick={() => setIsLogin(true)}
          >
            登录
          </Tab>
          <Tab
            active={!isLogin}
            onClick={() => setIsLogin(false)}
          >
            注册
          </Tab>
        </TabContainer>

        <Form onSubmit={handleSubmit}>
          {!isLogin && (
            <Input
              type="text"
              name="username"
              placeholder="用户名"
              value={formData.username}
              onChange={handleChange}
              required
            />
          )}
          <Input
            type="email"
            name="email"
            placeholder="邮箱"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <Input
            type="password"
            name="password"
            placeholder="密码"
            value={formData.password}
            onChange={handleChange}
            required
          />
          {!isLogin && (
            <>
              <Input
                type="text"
                name="verificationCode"
                placeholder="验证码"
                value={formData.verificationCode}
                onChange={handleChange}
                required
              />
              <Button
                type="button"
                onClick={handleSendCode}
                disabled={isLoading || !formData.email}
              >
                发送验证码
              </Button>
            </>
          )}
          <Button type="submit" disabled={isLoading}>
            {isLoading ? '处理中...' : isLogin ? '登录' : '注册'}
          </Button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
      </AuthCard>
    </AuthContainer>
  );
}

export default AuthPage; 